var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("default", null, { actions: { open: _vm.open } }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "modal", staticClass: "uk-flex-top", attrs: { "uk-modal": "" } },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
            },
            [
              _c("button", {
                staticClass: "uk-modal-close-default",
                attrs: { type: "button", "uk-close": "" }
              }),
              _vm._v(" "),
              _vm._t("modal", [_vm._v("[MODAL CONTENT MISSING]")], {
                data: {
                  ctx: _vm.ctx,
                  reset: _vm.reset,
                  protected: _vm.protected
                },
                actions: { callback: _vm.callback }
              })
            ],
            2
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }