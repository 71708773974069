var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "modal",
        class: [_vm.sizeClass],
        attrs: { id: "modal-center", "uk-modal": "" }
      },
      [
        _c("div", { staticClass: "uk-modal-dialog uk-margin-auto-vertical" }, [
          _c("button", {
            staticClass: "uk-modal-close-default",
            attrs: { type: "button", "uk-close": "" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "uk-modal-body" }, [_vm._t("default")], 2)
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }