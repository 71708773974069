<template>
    <div>
        <slot :actions="{ open }"></slot>
        <div ref="modal" class="uk-flex-top" uk-modal>
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <slot name="modal" :data="{ ctx, reset, protected }" :actions="{ callback }">[MODAL CONTENT MISSING]</slot>
            </div>
        </div>
    </div>
</template>

<script>
import UIkit from 'uikit'
import EventBus from './EventBus'

export default {
    name: "ModalComponent",
    props:{
        trigger:{
            type: String,
            default: ''
        },
        storage:{
            type: String,
            default: "hx_download_protected"
        },
        fullScreen: {
            type: Boolean,
            default: false
        },
        closeTimeout:{
            type: Number,
            default: 3000
        }
    },
    data(){
        return {
            ctx: {},
            protected: true,
            reset: false,
        }
    },
    created(){
        this.protected = (sessionStorage.getItem(this.storage)) ? false : true
    },
    mounted(){
        this.reset = !this.reset
        if(this.trigger !== ''){
            EventBus.$on('modal/trigger',( e )=>{
                if(e.trigger === this.trigger) this.open(e)
            })
        }
    },
    methods:{
        handleCallbacks(ctx){
            if(ctx && ctx.onSubmit){
                switch (ctx.onSubmit.action){
                    case "download":
                        sessionStorage.setItem(this.storage, 1)
                        //alert(ctx.onSubmit.value)
                        window.location.href = ctx.onSubmit.value
                        break;
                }
            }
        },
        open(ctx){
            this.protected = (sessionStorage.getItem(this.storage)) ? false : true

            this.ctx = { ...ctx }
            UIkit.modal(this.$refs.modal).show()

            /* if(this.protected){
                UIkit.modal(this.$refs.modal).show()
            }else{
                this.handleCallbacks(ctx)
            } */

        },
        callback(ctx){

            this.handleCallbacks(ctx)

            setTimeout(()=>{
                UIkit.modal(this.$refs.modal).hide()
            },this.closeTimeout)

            setTimeout(()=>{
                this.reset = !this.reset
                this.protected = false
            },this.closeTimeout + 1000)
        }
    }
}
</script>

<style>
</style>
