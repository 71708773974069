//import 'core-js';
import 'core-js/features/dom-collections';
import 'core-js/features/promise';
import 'core-js/features/object';
import 'core-js/features/array';
import UIkit, { util, toggle } from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import Vue from 'vue'
import EventBus from './components/EventBus'
import GdprPopup from './components/GdprPopup'
import PopupComponent from './components/PopupComponent'
import ModalComponent from './components/ModalComponent'
import Router from './components/Router'

window.gtag = (typeof gtag !== 'undefined') ? gtag : false
window.fbq = (typeof fbq !== 'undefined') ? fbq : false

Vue.component('GdprPopup',GdprPopup)
Vue.component('PopupComponent',PopupComponent)
Vue.component('ModalComponent',ModalComponent)

Vue.directive('tracking', {
  bind(el, { value }, vnode) {
    vnode.tracking = {}
    vnode.tracking['click'] = () => {
      EventBus.$emit('hx/track/conversion', value)
    }
    el.addEventListener('click', vnode.tracking['click'])
  },
  unbind(el, binding, vnode) {
    if(vnode.tracking['click']) el.removeEventListener("click", vnode.tracking['click'])
  }
})

Vue.config.productionTip = false

UIkit.use(Icons)

if (document.getElementById("root")) {
  new Vue({
    el: '#root',
    router: Router,
    mounted: () => {
      console.log('Mounted !!!')
    },
    methods: {
      clickInterest: function (event) {
        var targetId = event.currentTarget.id;
        this.$refs.ctf_interest.value = targetId;
      }
    }
  })
}

// Conversion tracking

const trackConversion = (e) => {

  /* GOOGLE */
  if(gtag){
    gtag('event', (e.event ? e.event : 'generate_lead'), {
      'event_category': e.category ? e.category : 'engagement',
      'event_label': e.label ? e.label : '',
      'event_callback': function( res, data ) { console.log(res,data) }
    })
    console.log("GOOGLE: track conversion", e)
  }

  /* FACEBOOK */
  if(fbq){
    fbq('track', 'Lead', {
      content_category: e.event ? e.event : 'submit_form',
      content_name: e.label ? e.label : ''
    })
    console.log("FACEBOOK: track conversion", e)
  }

}

EventBus.$on('hx/track/conversion', trackConversion)

// EventBus demo

EventBus.$on('notifiy', message => {
  if (message) UIkit.notification(`<span uk-icon='icon: check'></span> ${message}`, { status: 'primary' })
})
