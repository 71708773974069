var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMe
    ? _c(
        "div",
        {
          staticClass:
            "uk-position-small uk-position-bottom-right uk-position-fixed uk-width-1-2@s uk-width-1-3@m uk-width-1-4@xl",
          staticStyle: { "z-index": "10" },
          attrs: { id: "gdpr_popup" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "uk-background-primary uk-light uk-box-shadow-small uk-padding"
            },
            [
              _c("a", {
                staticClass: "uk-alert-close",
                attrs: { "uk-close": "" },
                on: {
                  click: function($event) {
                    return _vm.acceptCookies()
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "uk-text-small" },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-small uk-button-default",
                  on: {
                    click: function($event) {
                      return _vm.acceptCookies()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.button))]
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }