import { render, staticRenderFns } from "./GdprPopup.vue?vue&type=template&id=0e472a9e&"
import script from "./GdprPopup.vue?vue&type=script&lang=js&"
export * from "./GdprPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nchanterie/code_hooox/basaltolen-website-processwire/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e472a9e')) {
      api.createRecord('0e472a9e', component.options)
    } else {
      api.reload('0e472a9e', component.options)
    }
    module.hot.accept("./GdprPopup.vue?vue&type=template&id=0e472a9e&", function () {
      api.rerender('0e472a9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/components/GdprPopup.vue"
export default component.exports