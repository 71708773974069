<template>
    <div>
        <div id="modal-center" :class="[sizeClass]" ref="modal" uk-modal>
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                <div class="uk-modal-body">
                    <slot></slot>
                </div>            
            </div>
        </div>
    </div>
</template>

<script>

import UIkit from 'uikit'
import Cookies from 'js-cookie'

export default {
    name: "PopupComponent",
    props:{
        uid:{
            type: String,
            default: "popup"
        },
        size:{
            type: String,
            default: "default"
        },
        timeout: {
            type: Number,
            default: 1000
        },
        limit: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {}
    },
    computed:{
        viewCount(){
            return (Cookies.get(this.uid)) ? parseInt(Cookies.get(this.uid)) : 0
        },
        sizeClass(){
            switch(this.size){
                case "large":
                    return "uk-modal-container"
                    break;
                default:
                    return ""
                    break;
            }
        }
    },
    mounted(){
        if(this.limit == 0 || this.limit > this.viewCount ){
            setTimeout( ()=>{
                UIkit.modal(this.$refs.modal).show()
                Cookies.set(this.uid, (this.viewCount + 1), { expires: 1 })
            },this.timeout)
        }           
    }
}
</script>

<style>

</style>
