<template>
<div id="gdpr_popup" style="z-index: 10" class="uk-position-small uk-position-bottom-right uk-position-fixed uk-width-1-2@s uk-width-1-3@m uk-width-1-4@xl" v-if="showMe">
    <div class="uk-background-primary uk-light uk-box-shadow-small uk-padding">
        <a @click="acceptCookies()" class="uk-alert-close" uk-close></a>
        <div class="uk-text-small">
            <slot></slot>
        </div>
        <button class="uk-button uk-button-small uk-button-default" @click="acceptCookies()">{{ button }}</button>
    </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  name: 'GdprPopup',
  props:{
    button: String,
    timestamp: String
  },
  data(){
    return {
      show: true
    }
  },
  mounted(){
      window.gtag = (typeof gtag !== 'undefined') ? gtag : false
      window.fbq = (typeof fbq !== 'undefined') ? fbq : false
  },
  computed: {
      showMe: function () {
        let test = (Cookies.get('gdpr')) ? false : true
        return test && this.show
      }
  },
  methods: {
    acceptCookies: function () {
      this.show = false
      Cookies.set('gdpr', 'gdpr_approved_' + this.timestamp, { expires: 365 })
      if(fbq) fbq('consent', 'grant')
    }
  }
}
</script>
